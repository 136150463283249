import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Row, Col, Card, Form, Container as BSContainer } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Buffer } from "buffer";
import { Spinner } from "../../pages/Spinner"

export const DataSourceMulti = (props) => {
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [id, setId] = useState(0);
  const [dataSources, setDataSources] = useState([]);
  const dataSourcesRef = useRef([]);
  dataSourcesRef.current = dataSources;

  const [updateDataSources, setUpdateDataSources] = useState(false);

  const [dataSourceUrl, setDataSourceUrl] = useState("");
  const dataSourceUrlRef = useRef("");
  dataSourceUrlRef.current = dataSourceUrl;

  const [dataSourceTitle, setDataSourceTitle] = useState("");
  const dataSourceTitleRef = useRef("");
  dataSourceTitleRef.current = dataSourceTitle;

  const [dataSourceSelectedOption, setDataSourceSelectedOption] = useState("");
  const dataSourceSelectedOptionRef = useRef("");
  dataSourceSelectedOptionRef.current = dataSourceSelectedOption;

  const [dataSourceEndpoint, setDataSourceEndpoint] = useState("");
  const dataSourceEndpointRef = useRef("");
  dataSourceEndpointRef.current = dataSourceEndpoint;

  const [isLoading, setIsLoading] = useState(false)

  const [validated, setValidated] = useState(false);

  const formRef = useRef(null);
  const fileNameRef = useRef("");

  const getDataSource = async (event) => {
    if (formRef.current.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true)
      setUpdateDataSources(true);
      if (dataSourceSelectedOptionRef.current === 2) {
        const newId = id + 1;
        setId(newId);
        setDataSources((dataSources) => [...dataSources, { name: fileName, file: "", title: "", url: dataSourceEndpoint, id: newId }]);
        setIsLoading(false)
      } else if (dataSourceSelectedOptionRef.current === 1) {
        const reader = new FileReader();
        await reader.addEventListener("load", () => {
          const uploaded_datasource = reader.result;
          const base64result = Buffer.from(uploaded_datasource).toString("base64");

          const newId = id + 1;
          setId(newId);
          setDataSources((dataSources) => [...dataSources, { name: fileName, file: base64result, title: selectedFile.name, url: "", id: newId }]);
          setIsLoading(false)
          setValidated(true);
          resetValues()
        })
        await reader.addEventListener("error", () => {
          setIsLoading(false)
        });
        if (selectedFile !== undefined && selectedFile !== null) {
          await reader.readAsBinaryString(selectedFile)
        } else {
          setIsLoading(false)
        }
      }
    }
  };

  const resetValues = () => {
    setDataSourceEndpoint("")
    setFileName("")
    setDataSourceSelectedOption("")

    dataSourceEndpointRef.value = "";
    fileNameRef.value = "";

    formRef.current.reset();
    setValidated(false);
  }

  const getFile = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onChangeFileSelector = (event) => {
    setFileName(event.target.value);
  };

  const onChangeEndpoint = (event) => {
    setDataSourceEndpoint(event.target.value);
  };

  const removeFile = async (id) => {
    const trashToDisabled = document.getElementById("trash_" + id);
    trashToDisabled.disabled = true

    const spinnerToShow = "spinner_" + id;
    let spinnerIcon = document.getElementById(spinnerToShow)
    spinnerIcon.className = "";

    setUpdateDataSources(true);
    let newDatasources = dataSources.filter(ds => {
      return ds.id !== id
    })
    setDataSources([...newDatasources]);
    trashToDisabled.disabled = false;
    spinnerIcon.className = "d-none"
  };

  const clickChooseFile = () => {
    document.querySelector("#fileName").value = "";
    setFileName("");
  };

  if (initialLoad) {
    let values = props.initialValuesFromAPI.filter((iv) => iv.id === "canvasDataSourceId");
    if (values.length !== 0) {
      let inputs = values[0].inputs;
      let mappedInputs = inputs.map((i) => {
        setId(Number(i[1].dataSourceId));
        return {
          ["name"]: i[1].dataSourceName,
          ["file"]: i[1].dataSourceFile,
          ["title"]: i[1].dataSourceTitle,
          ["url"]: i[1].dataSourceEndpoint,
          ["id"]: i[1].dataSourceId,
        };
      });
      setDataSources(mappedInputs);
      setInitialLoad(false);
    }
  }

  const dataSourceSelectedOptionSelected = (event) => {
    dataSourceEndpointRef.current = "";
    setDataSourceEndpoint("");
    dataSourceTitleRef.current = "";
    setDataSourceTitle("");
    dataSourceUrlRef.current = "";
    setDataSourceUrl("");

    dataSourceSelectedOptionRef.current = event.target.selectedIndex;
    setDataSourceSelectedOption(event.target.selectedIndex);
  };

  useEffect(() => {
    if (updateDataSources) {
      setUpdateDataSources(false);
      props.hitSaveBtn(formRef, true, false);
    }
  }, [dataSources]);

  return (
    <Card>
      <BSContainer>
        <Card className="my-1 childContent dataSourceTable" id="canvasDataSourceId" key="canvasDataSourceKey">
          <BSContainer>
            <Form ref={formRef} noValidate validated={validated}>
              <Form.Group className="mt-3 mb-2">
                <Form.Label>Data Sources</Form.Label>
                <Form.Control
                  className={`${props.isProd ? "mb-1 blockId d-none" : "mb-1 blockId"}`}
                  readOnly
                  type="datasource"
                  value="canvasDataSourceId"
                  placeholder="Enter Block ID"
                />
                <Form.Control
                  className={`${props.isProd ? "mb-1 blockKey d-none" : "mb-1 blockKey"}`}
                  readOnly
                  type="datasource"
                  value="canvasDataSourceKey"
                  placeholder="Enter Block Key"
                />
                <Form.Group>
                  <Form.Select ref={dataSourceSelectedOptionRef} value={dataSourceSelectedOptionRef.current} id="dataSourceSelectedOption" onChange={dataSourceSelectedOptionSelected} type="fieldselect" className="fieldselect" required>
                    <option value="">Select One</option>
                    <option value="1">File</option>
                    <option value="2">Endpoint</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please select an option!</Form.Control.Feedback>
                </Form.Group>
                {dataSourceSelectedOptionRef.current === 1 ? (
                  <Form.Group>
                    <Form.Control type="file" id="dataSourceFile"
                      onChange={getFile}
                      onClick={clickChooseFile}
                      className="valueField" accept=".json" required />
                    <Form.Control.Feedback type="invalid">Please select a file!</Form.Control.Feedback>
                  </Form.Group>
                ) : dataSourceSelectedOptionRef.current === 2 ? (
                  <Form.Group>
                    <Form.Control type="input" id="dataSourceEndpoint" defaultValue={dataSourceEndpoint} placeholder="Enter Endpoint URL" onChange={onChangeEndpoint} className="valueField" required />
                    <Form.Control.Feedback type="invalid">Datasource URL required!</Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <></>
                )}
                <Form.Group>
                  <Form.Control ref={fileNameRef} type="fieldname" id="fileName" onChange={onChangeFileSelector} defaultValue={fileName} placeholder="Enter Name" className="valueField" required />
                  <Form.Control.Feedback type="invalid">Name field required!</Form.Control.Feedback>
                </Form.Group>
                <Row className="pt-2">
                  <Col>
                    <Button variant="primary" onClick={getDataSource} disabled={isLoading}>
                      Save
                    </Button>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    {isLoading ? <Spinner width={40} isActive={true} /> : <></>}
                  </Col>
                </Row>
              </Form.Group>
            </Form>
            <Form.Group className="mb-3 mappingInput">
              {dataSources.map((asset) => {
                return (
                  <div className="mappingFields" id={`${asset.id}_mappingInput`} key={asset.id}>
                    <div key={asset.id} id="dsContain">
                      <BSContainer>
                        <Row className="mb-2">
                          <Col xs="8">
                            <Form.Control type="fieldname" id="dataSourceName" defaultValue={asset.name} readOnly className="valueField" />
                          </Col>
                          <Col className="p-0">
                            <Button type="button" onClick={() => removeFile(asset.id)} id={"trash_" + asset.id} className="btn btn-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </Col>
                          <Col className="p-0" style={{ textAlign: "right" }}>
                            <div className="d-none" id={"spinner_" + asset.id} ><Spinner width={40} isActive={true} /></div>
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="dataSourceId" defaultValue={asset.id} readOnly className="valueField" />
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="dataSourceEndpoint" defaultValue={asset.url} readOnly className="valueField" />
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="dataSourceFile" defaultValue={asset.file} readOnly className="valueField" />
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="dataSourceTitle" defaultValue={asset.title} readOnly className="valueField" />
                          </Col>
                        </Row>
                      </BSContainer>
                    </div>
                  </div>
                );
              })}
            </Form.Group>
          </BSContainer>
        </Card>
      </BSContainer>
    </Card>
  );
};
