import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/RestrictedPage.module.css";

library.add(faLock);

export const RestrictedPage = () => {
    return (
        <>
            <div className="centeredBody">
                <div className="centeredIcon">
                    <FontAwesomeIcon icon="lock" size="5x" />
                </div>
                <div className="message">
                    <h1 className={styles.h1}>Access restricted</h1>
                    <p className={styles.p}>Please ask your administrator for instructions.</p>
                </div>
            </div>
        </>
    );
};
