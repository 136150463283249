import { Container } from "./Container";

export const Admin = (props) => {
    return (
        <Container
            pageId={props.pageId}
            configId={props.configId}
            userAccount={props.userAccount}
            isAdmin={true}
            isProd={props.isProd}
        />
    );
};
