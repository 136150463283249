import { RotatingLines } from 'react-loader-spinner'

export const Spinner = (props) => {
    return (
        <RotatingLines
            strokeColor="#6c757d"
            strokeWidth="5"
            animationDuration="0.75"
            width={props.width}
            visible={props.isActive}
        />
    )
}
