import { withSize } from "react-sizeme";
import GridLayout from "react-grid-layout";

const rowHeight = 1;

export const SizeAwareDraggableParent = withSize({ refreshMode: "debounce", refreshRate: 60 })(DraggableParent);
function DraggableParent(props) {
  let layout = [];
  layout = props.layouts.map((l) => {
    if (!props.isAdmin) {
      l.isDraggable = false;
      l.isResizable = false;
    }
    l.moved = true;
    return l;
  });
  let newLayout = layout.map((item) => ({ ...item }));

  return (
    <div id="main-rgl">
      <GridLayout
        className="layout"
        layout={newLayout}
        cols={12}
        margin={[0, 0]}
        containerPadding={[0, 0]}
        rowHeight={rowHeight}
        width={props.size.width}
        onLayoutChange={props.onLayoutChange}
      >
        {props.contentArr.map((content) => {
          return content;
        })}
      </GridLayout>
    </div>
  );
}
