import React, { useEffect, useState } from "react";

export const ButtonsBlock = (props) => {
  const [backgroundColorButton, setBackgroundColorButton] = useState("");
  const [fontSizeButton, setFontSizeButton] = useState("");
  const [fontColorButton, setFontColorButton] = useState("");
  const [fontFamilyButton, setFontFamilyButton] = useState("");
  const [italicButton, setItalicButton] = useState("");
  const [boldButton, setBoldButton] = useState("");
  const [underlineButton, setUnderlineButton] = useState("");

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For ButtonsBlock", props.id);
  }

  const contentToDisplay = props.mapping.map((button, idx) => {
    let buttonStyle = {
      fontSize: fontSizeButton,
      color: fontColorButton,
      fontFamily: fontFamilyButton,
      fontStyle: italicButton ? "italic" : "none",
      fontWeight: boldButton ? "bold" : "none",
      textDecoration: underlineButton ? "underline" : "none",
      backgroundColor: backgroundColorButton,
    };

    let content;
    if (button[1].buttonUrl.length === 0) {
      content = (
        <button style={buttonStyle} type="button" key={idx + "_button"} className="btn btn-outline-secondary me-1 disabled">
          {button[1].buttonName}
        </button>
      );
    } else {
      let url;
      try {
        url = new URL(button[1].buttonUrl);
      } catch (ex) {
        console.warn("Invalid URL for button", button[1].buttonName, ex);
        url = button[1].buttonUrl;
      }
      content = (
        <a style={buttonStyle} href={url} key={idx + "_button"} rel="noopener noreferrer" target="_blank" className="btn btn-outline-secondary me-1">
          {button[1].buttonName}
        </a>
      );
    }
    return content;
  });

  useEffect(() => {
    if (props.blockStyles !== undefined) {
      props.blockStyles.forEach((bs) => {
        if (bs["bgColorTitle"] !== undefined) {
          setBackgroundColorButton(bs["bgColorTitle"]);
        } else if (bs["fontSizeInputTitle"] !== undefined) {
          setFontSizeButton(bs["fontSizeInputTitle"]);
        } else if (bs["fontColorInputTitle"] !== undefined) {
          setFontColorButton(bs["fontColorInputTitle"]);
        } else if (bs["fontFamilyInputTitle"] !== undefined) {
          setFontFamilyButton(bs["fontFamilyInputTitle"]);
        } else if (bs["italicTitle"] !== undefined) {
          setItalicButton(bs["italicTitle"]);
        } else if (bs["boldTitle"] !== undefined) {
          setBoldButton(bs["boldTitle"]);
        } else if (bs["underlineTitle"] !== undefined) {
          setUnderlineButton(bs["underlineTitle"]);
        }
      });
    }
  }, [props.blockStyles]);

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  }, []);

  return (
    <div id={props.keyId} className="d-flex-inline justify-content-around mt-2">
      {contentToDisplay}
    </div>
  );
};
