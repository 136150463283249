import React, { useEffect } from "react";
import { Container as BSContainer } from "react-bootstrap";

export const SpacerBlock = (props) => {
  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  }, []);

  return (
    <BSContainer className="spacerBlock p-0 d-flex w-100" id={props.keyId} />
  );
};
