import React, { useState } from "react";
import { Form, Container as BSContainer, Row, Col } from "react-bootstrap";

export const Search = (props) => {
  const [buttonTitle, setButtonTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [isQueryParam, setIsQueryParam] = useState(false);
  const [queryParam, setQueryParam] = useState("");

  const handleTick = () => {
    setIsQueryParam(!isQueryParam);
    if (!isQueryParam) {
      setQueryParam("");
    }
  };
  
  if (initialLoad) {
    if (props.initialValuesFromAPI !== undefined) {
      let blockValues = props.initialValuesFromAPI.find((iv) => iv.id === String(props.blockId));
      if (blockValues !== undefined) {
        let ourBlockValues = blockValues.inputs.find((bv) => bv[0] === "1_mappingInput");
        if (ourBlockValues !== undefined && ourBlockValues !== null) {
          if (blockValues.blockType === "search") {
            for (const [key, value] of Object.entries(ourBlockValues[1])) {
              if (key === "buttonTitle") {
                setButtonTitle(value);
              } else if (key === "searchText") {
                setSearchText(value);
              } else if (key === "isQueryParam") {
                setIsQueryParam(value);
              } else if (key === "queryParam") {
                setQueryParam(value);
              }
            }
          }
        }
      }
    }
    setInitialLoad(false);
  }

  return (
    <Row id="1_mappingInput" className="px-0 pl-1 pb-1 mappingFields">
      <BSContainer id="searchContain" className="py-2">
        <Col sm="auto">
          <Form.Control id="buttonTitle" type="input" className="valueField mb-1" defaultValue={buttonTitle} placeholder="Enter Button Title" />
        </Col>
        <Col sm="auto">
          <Form.Control id="searchText" type="input" className="valueField" defaultValue={searchText} placeholder="Enter Search Title" />
        </Col>
        <Col sm="auto">
          <Form.Check id="isQueryParam" type="checkbox" checked={isQueryParam} onChange={handleTick} className="valueField" label="Is Query Param?" />
        </Col>
        {isQueryParam ? (
          <Col sm="auto">
            <Form.Control id="queryParam" type="input" className="valueField" defaultValue={queryParam} placeholder="Enter Query String" />
          </Col>
        ) : (
          <></>
        )}
      </BSContainer>
    </Row>
  );
};
