import React from "react";
import { Modal } from "react-bootstrap";
import OkCancelButtons from "./OkCancelButtons";

const ConfirmationModal = (props) => {
    return (
        <Modal centered show={props.show}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <OkCancelButtons okText="Yes" cancelText="No" onOkClick={() => props.onYesClick(props.item, props.itemId)} onCancelClick={props.onNoClick} />
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;