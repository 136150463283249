import React, { useState, useEffect } from "react";
import { Container as BSContainer, Row, Col } from "react-bootstrap";

export const FreeFormBlock = (props) => {
  const [content, setContent] = useState([]);

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For FreeformBlock", props.id);
  }

  function loadContent() {
    setContent([]);
    props.mapping.forEach((data, idx) => {
      let newContent = [];
      const id = idx + 1;
      if (data[1].inputType === "textContain") {
        newContent.push(
          <Col
            style={{
              backgroundColor: data[1].backgroundColorInput,
              color: data[1].fontColorInput,
              borderColor: data[1].borderColorInput,
              fontFamily: data[1].fontFamilyInput,
              fontStyle: data[1].italicBody ? "italic" : "none",
              textDecoration: data[1].underlineBody ? "underline" : "none",
              fontWeight: data[1].boldBody ? "bold" : "none",
            }}
            className={[
              "d-flex align-items-center " + data[1].alignmentInput,
              data[1].fontSizeInput,
              "p-" + data[1].paddingSelectInput,
              data[1].borderTypeInput,
            ]}
            key={id + "_ffText"}
            id={id + "_ffText"}
          >
            {data[1].textInput}
          </Col>
        );
        setContent((content) => [...content, newContent]);
      } else if (data[1].inputType === "imageContain") {
        let newContent = [];
        let imageUrl = data[1].imageUrl;
        let relPathName = imageUrl.split("?");
        let relPath = relPathName[0]
        newContent.push(
          <Col
            style={{ backgroundColor: data[1].backgroundColorInput }}
            className={["h-100 w-100 d-flex align-items-center " + data[1].alignmentInput, "p-" + data[1].paddingSelectInput, data[1].borderTypeInput]}
            key={id + "_ffImage"}
            id={id + "_ffImage"}
          >
            <img style={{ maxWidth: "100%", height: "auto" }} id="uploadedImage" src={relPath} alt={data[1].imageTitle} title={data[1].imageTitle} />
          </Col>
        );
        setContent((content) => [...content, newContent]);
      }
    });
  }

  if (content.length === 0 && props.mapping.length > 0) {
    loadContent();
  }

  useEffect(() => {
    loadContent();
  }, [props]);

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  }, []);

  useEffect(() => {
    var imgs = document.querySelectorAll('img')
    imgs.forEach(img => {
      img.addEventListener('load', loaded);
    });
  }, [])

  const loaded = () => {
    props.onLayoutChangeBlock(props.allLayouts);
  }

  return (
    <div className="mt-2" id={props.keyId}>
      <BSContainer className="p-0 h-100 w-100">
        <Row className="m-0 h-100 w-100">{content}</Row>
      </BSContainer>
    </div>
  );
};
