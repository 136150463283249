import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Row, Col, Card, Form, Container as BSContainer } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { WebAppConfigs } from "../../onsight-plus";
import { Spinner } from "../../pages/Spinner"

export const FileUpload = (props) => {
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [assets, setAssets] = useState([]);
  const [id, setId] = useState(0);
  const [updateAssets, setUpdateAssets] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false);
  const formRef = useRef(null);
  const fileNameRef = useRef("");

  const getFile = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onChange = (event) => {
    setFileName(event.target.value);
  };

  const uploadFile = async (event) => {
    if (formRef.current.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true)
      setUpdateAssets(true);
      const formData = new FormData();
      formData.append("asset", selectedFile);
      await WebAppConfigs.postAsset(formData, "images")
        .then(result => {
          let location = decodeURI(result);
          if (location.includes("%26")) {
            location = location.replace("%26", "&")
          }
          const newId = id + 1;
          setId(newId);
          setAssets((assets) => [...assets, { name: fileName, location: location, id: newId }]);
          setIsLoading(false)
          setValidated(true);
          resetValues()
        })
        .catch(ex => {
          console.log(ex)
          setIsLoading(false)
        })
    }
  };

  const resetValues = () => {
    setFileName("")
    fileNameRef.current.value = "";

    formRef.current.reset();
    setValidated(false);
  }

  const updateConfig = (spinnerIcon, trashIcon, assets, id) => {
    let newAssets = [];
    assets.forEach((a) => {
      if (a.id !== id) {
        newAssets.push(a);
      }
    });
    setAssets([...newAssets]);
    trashIcon.disabled = false
    spinnerIcon.className = "d-none"
  }

  const removeFile = async (id) => {
    const idToDisabled = "trash_" + id;
    let trashIcon = document.getElementById(idToDisabled)
    trashIcon.disabled = true;

    const spinnerToShow = "spinner_" + id;
    let spinnerIcon = document.getElementById(spinnerToShow)
    spinnerIcon.className = "";

    setUpdateAssets(true);
    let asset = assets.find((a) => a.id === id);
    let relPathName = asset.location.split("?");
    let relPath = relPathName[0].replace(`https://cogswellsprocketssto.blob.core.windows.net/assets/${process.env.REACT_APP_B2C_CLIENT_ID}/`, "");
    await WebAppConfigs.removeAsset(relPath)
      .then(() => {
        updateConfig(spinnerIcon, trashIcon, assets, id)
      })
      .catch(ex => {
        console.log(ex)
        updateConfig(spinnerIcon, trashIcon, assets, id)
      })
  };

  const clickChooseFile = () => {
    document.querySelector("#fileName").value = "";
    setFileName("");
  };

  if (initialLoad) {
    let values = props.initialValuesFromAPI.filter((iv) => iv.id === "fileUploadId");
    if (values.length !== 0) {
      let inputs = values[0].inputs;
      let mappedInputs = inputs.map((i) => {
        setId(Number(i[1].assetId));
        return {
          ["id"]: i[1].assetId,
          ["name"]: i[1].assetName,
          ["location"]: i[1].assetUrl,
        };
      });
      setAssets(mappedInputs);
      setInitialLoad(false);
    }
  }

  useEffect(() => {
    if (updateAssets) {
      setUpdateAssets(false);
      props.hitSaveBtn(formRef, true, false);
    }
  }, [assets]);

  return (
    <Card className="px-0 pl-1 pb-1 mt-1 mb-2">
      <BSContainer>
        <Card className="my-1 childContent fileUploadTable" id="fileUploadId" key="fileUploadKey">
          <BSContainer fluid={true}>
            <Form ref={formRef} noValidate validated={validated}>
              <Form.Group className="mt-3 mb-2">
                <Form.Label>Media Uploader</Form.Label>
                <Form.Control
                  className={`${props.isProd ? "mb-1 blockId d-none" : "mb-1 blockId"}`}
                  readOnly
                  type="fieldname"
                  value="fileUploadId"
                  placeholder="Enter Block ID"
                />
                <Form.Control
                  className={`${props.isProd ? "mb-1 blockKey d-none" : "mb-1 blockKey"}`}
                  readOnly
                  type="fieldname"
                  value="fileUploadKey"
                  placeholder="Enter Block Key"
                />
                <Form.Control
                  type="file"
                  id="fileUploadFile"
                  onClick={clickChooseFile}
                  onChange={getFile}
                  className="valueField"
                  accept=".pdf, image/jpeg, image/png, image/jpg"
                  required
                />
                <Form.Control.Feedback type="invalid">Please select a file!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control ref={fileNameRef} type="fieldname" id="fileName" onChange={onChange} defaultValue={fileName} placeholder="Enter Name" className="valueField" required />
                <Form.Control.Feedback type="invalid">Name field required!</Form.Control.Feedback>
              </Form.Group>
              <Row className="pt-2">
                <Col>
                  <Button disabled={isLoading} variant="primary" onClick={uploadFile} id="uploadButton">
                    Upload
                  </Button>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  {isLoading ? <Spinner width={40} isActive={true} /> : <></>}
                </Col>
              </Row>
            </Form>
            <Form.Group className="mb-3 mappingInput">
              {assets.map((asset) => {
                return (
                  <div className="mappingFields" id={`${asset.id}_mappingInput`} key={asset.id}>
                    <div key={asset.id} id="fuContain">
                      <BSContainer>
                        <Row className="mb-2">
                          <Col xs="8">
                            <Form.Control type="fieldname" id="assetName" defaultValue={asset.name} readOnly className="valueField" />
                          </Col>
                          <Col className="p-0">
                            <Button type="button" onClick={() => removeFile(asset.id)} id={"trash_" + asset.id} className="btn btn-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </Col>
                          <Col className="p-0" style={{ textAlign: "right" }}>
                            <div className="d-none" id={"spinner_" + asset.id} ><Spinner width={40} isActive={true} /></div>
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="assetUrl" defaultValue={asset.location} readOnly className="valueField" />
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="assetId" defaultValue={asset.id} readOnly className="valueField" />
                          </Col>
                        </Row>
                      </BSContainer>
                    </div>
                  </div>
                );
              })}
            </Form.Group>
          </BSContainer>
        </Card>
      </BSContainer>
    </Card>
  );
};
